<template>
  <v-container fluid class="profile scroll-container" v-if="userData">
    <CategoryTitle :category="category" />

    <v-expansion-panels flat accordion multiple v-model="openPanels">
      <v-expansion-panel>
        <v-expansion-panel-header>
          <div class="text-h4 mb-3">
            {{ $t("profile.header.personalData") }}
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-form ref="profileform" v-model="isProfileFormValid">
            <v-row dense no-gutters class="mb-3">
              <v-col cols="12" sm="6">
                <!-- nome -->
                <div class="text-caption mb-1">
                  {{ $t("profile.firstName") }}
                </div>
                <v-text-field
                  v-model="userData.person.firstName"
                  readonly
                  :rules="[requiredRules()]"
                  required
                  filled
                  solo
                  dense
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6">
                <!-- cognome -->
                <div class="text-caption mb-1">
                  {{ $t("profile.lastName") }}
                </div>
                <v-text-field
                  v-model="userData.person.lastName"
                  readonly
                  :rules="[requiredRules()]"
                  filled
                  solo
                  dense
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6">
                <!-- data di nascita -->
                <div class="text-caption mb-1">
                  {{ $t("profile.birthDate") }}
                </div>
                <v-text-field
                  class="max-w-little"
                  v-model="birthDate"
                  readonly
                  :rules="dateRules"
                  v-mask="'##/##/####'"
                  placeholder="__/__/____"
                  required
                  filled
                  solo
                  dense
                >
                </v-text-field>
              </v-col>

              <v-col cols="12" sm="6">
                <!-- telefono -->
                <div class="text-caption mb-1">
                  {{ $t("profile.phone") }}
                </div>
                <v-text-field
                  class="max-w-medium"
                  v-model="userData.phone"
                  required
                  :rules="[requiredRules()]"
                  v-mask="'###############'"
                  filled
                  solo
                  dense
                ></v-text-field>
              </v-col>
            </v-row>

            <div class="text-h4 mb-3">{{ $t("profile.header.address") }}</div>
            <v-row dense no-gutters>
              <v-col cols="12" sm="6">
                <!-- indirizzo -->
                <div class="text-caption mb-1">
                  {{ $t("profile.addresses.address") }}
                </div>
                <v-text-field
                  v-model="userData.billingAddress.address1"
                  :rules="[requiredValue()]"
                  required
                  filled
                  solo
                  dense
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6">
                <!-- numero civico -->
                <div class="text-caption mb-1">
                  {{ $t("profile.addresses.addressNumber") }}
                </div>
                <v-text-field
                  class="max-w-little"
                  v-model="userData.billingAddress.addressNumber"
                  :rules="[requiredValue()]"
                  required
                  filled
                  solo
                  dense
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6">
                <!-- città -->
                <div class="text-caption mb-1">
                  {{ $t("profile.addresses.city") }}
                </div>
                <v-autocomplete
                  v-model="userData.billingAddress.city"
                  :items="cities"
                  required
                  :rules="[requiredValue()]"
                  filled
                  dense
                  solo
                  return-object
                  append-icon=""
                  height="40"
                  item-text="name"
                  item-value="name"
                  @input="setCityAndProvince"
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" sm="6">
                <!-- provincia -->
                <div class="text-caption mb-1">
                  {{ $t("profile.addresses.province") }}
                </div>
                <v-text-field
                  class="max-w-little"
                  v-model="userData.billingAddress.province"
                  :rules="[requiredValue()]"
                  required
                  readonly
                  filled
                  solo
                  dense
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6">
                <!-- numero carta -->
                <div class="text-caption mb-1">
                  {{ $t("profile.fidelityCard") }}
                </div>
                <v-text-field
                  class="max-w-medium"
                  v-model="userData.fidelityCard"
                  type="tel"
                  readonly
                  filled
                  v-mask="'###############'"
                  solo
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row
              no-gutters
              class="d-flex justify-center justify-md-end mt-10 mt-sm-6"
            >
              <v-btn
                color="primary"
                x-large
                elevation="0"
                min-width="185"
                :block="$vuetify.breakpoint.xs"
                :disabled="!isProfileFormValid"
                @click="updateProfile()"
                :loading="loadingUserChange"
              >
                {{ $t("profile.updatePersonalData") }}
              </v-btn>
            </v-row>
          </v-form>
        </v-expansion-panel-content>
        <v-divider class="my-2" />
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header>
          <div class="text-h4 mb-3">{{ $t("profile.header.email") }}</div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-form ref="emailform" v-model="isEmailFormValid">
            <v-row dense no-gutters>
              <v-col cols="12" sm="6">
                <div class="text-body-2 mb-1">
                  {{ $t("profile.email.email") }}
                </div>
                <v-text-field
                  v-model="this.userData.email"
                  :label="`${$t('register.cardCheck.email')}*`"
                  readonly
                  filled
                  solo
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <div class="text-body-2 mb-1">
                  {{ $t("profile.email.newEmail") }}
                </div>
                <v-text-field
                  v-model="newEmail"
                  :rules="emailRules"
                  :label="`${$t('register.cardCheck.email')}*`"
                  required
                  filled
                  solo
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <div class="text-body-2 mb-1">
                  {{ $t("profile.email.emailConfirm") }}
                </div>
                <v-text-field
                  :rules="emailConfirmRules"
                  :label="`${$t('register.cardCheck.emailConfirm')}*`"
                  required
                  filled
                  solo
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row
              no-gutters
              class="d-flex justify-center justify-md-end mt-10 mt-sm-6"
            >
              <v-btn
                color="primary"
                x-large
                elevation="0"
                min-width="185"
                :block="$vuetify.breakpoint.xs"
                :disabled="!isEmailFormValid"
                @click="updateEmail()"
                :loading="loadingEmailChange"
              >
                {{ $t("profile.updateEmailData") }}
              </v-btn>
            </v-row>
          </v-form>
        </v-expansion-panel-content>
        <v-divider class="my-2" />
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header>
          <div class="text-h4 mb-3">{{ $t("profile.header.password") }}</div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-form ref="passwordform" v-model="isPasswordFormValid">
            <v-row dense no-gutters>
              <v-col cols="12" sm="6">
                <!-- password -->
                <div class="text-body-2 mb-1">
                  {{ $t("profile.oldPassword") }}
                </div>
                <v-text-field
                  v-model="oldPassword"
                  :append-icon="showPassword ? '$eye' : '$eyeOff'"
                  :type="showPassword ? 'text' : 'password'"
                  :rules="passwordRules"
                  @click:append="toggleShowPassword"
                  required
                  filled
                  solo
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <!-- password -->
                <div class="text-body-2 mb-1">
                  {{ $t("profile.password") }}
                </div>
                <v-text-field
                  v-model="password"
                  :append-icon="showPassword ? '$eye' : '$eyeOff'"
                  :type="showPassword ? 'text' : 'password'"
                  :rules="passwordRulesNew"
                  @click:append="toggleShowPassword"
                  required
                  filled
                  solo
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <!-- conferma password -->
                <div class="text-body-2 mb-1">
                  {{ $t("profile.passwordConfirm") }}
                </div>
                <v-text-field
                  :append-icon="showPassword ? '$eye' : '$eyeOff'"
                  :type="showPassword ? 'text' : 'password'"
                  :rules="passwordConfirmRules"
                  @click:append="toggleShowPassword"
                  required
                  filled
                  solo
                  dense
                ></v-text-field>
              </v-col>
              <v-row
                no-gutters
                class="d-flex justify-center justify-md-end mt-10 mt-sm-6"
              >
                <v-btn
                  color="primary"
                  x-large
                  elevation="0"
                  min-width="185"
                  :block="$vuetify.breakpoint.xs"
                  :disabled="!isPasswordFormValid"
                  @click="updatePassword()"
                  :loading="loadingPasswordChange"
                >
                  {{ $t("profile.updatePassword") }}
                </v-btn>
              </v-row>
            </v-row>
          </v-form>
        </v-expansion-panel-content>
        <v-divider class="my-2" />
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header>
          <div class="text-h4 mb-3" v-if="showPreferences">
            {{ $t("profile.header.consent") }}
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-form ref="consentform" v-if="showPreferences">
            <!-- <h5 class="font-weight-bold">
              {{ $t("register.completeData.preferencesOptIn3") }}
            </h5>
            <v-radio-group dense v-model="disclaimers.newsletter" mandatory row>
              <v-radio color="default" :value="'true'"
                ><template v-slot:label>
                  <span class="">{{ $t("common.yes") }}</span>
                </template></v-radio
              >
              <v-radio color="default" :value="'false'"
                ><template v-slot:label>
                  <span class="">{{ $t("common.no") }}</span>
                </template></v-radio
              ></v-radio-group
            > -->

            <div class="mb-5">
              <!-- autorizzazione per fini statistici -->
              <v-checkbox
                hide-details
                required
                v-model="disclaimers.statistics"
                false-value="false"
                true-value="true"
              >
                <template v-slot:label>
                  <i18n path="register.completeData.acceptPre" tag="span">
                    <template v-slot:link>
                      <router-link :to="'/page/trattamento-dati-statistici'">
                        <span class="font-weight-bold default--text">
                          {{ $t("register.completeData.consent") }}</span
                        >
                      </router-link>
                    </template>
                  </i18n>
                </template>
              </v-checkbox>

              <!-- autorizzazione per fini di marketing -->
              <v-checkbox
                hide-details
                required
                v-model="disclaimers.marketing"
                false-value="false"
                true-value="true"
              >
                <template v-slot:label>
                  <i18n path="register.completeData.acceptPre" tag="span">
                    <template v-slot:link>
                      <router-link :to="'/page/trattamento-dati-marketing'">
                        <span class="font-weight-bold default--text">
                          {{ $t("register.completeData.marketing") }}</span
                        >
                      </router-link>
                    </template>
                  </i18n>
                </template>
              </v-checkbox>
            </div>
            <v-row
              no-gutters
              class="d-flex justify-center justify-md-end mt-10 mt-sm-6"
            >
              <v-btn
                color="primary"
                x-large
                elevation="0"
                min-width="185"
                :block="$vuetify.breakpoint.xs"
                @click="updateDisclaimers()"
                :loading="loadingDisclaimersChange"
              >
                {{ $t("profile.updateDisclaimers") }}
              </v-btn>
            </v-row>
          </v-form>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-row
      ><v-col v-if="$platform_is_cordova && isBetaTester" class="mt-8">
        <a :href="developmentLink">Apri versione di test </a></v-col
      ></v-row
    >
  </v-container>
</template>
<style lang="scss">
.profile {
  div[class*="col-"] {
    padding: 0 12px !important;
  }
  a {
    color: var(--v-default-base);
  }
  .v-autocomplete {
    box-shadow: none;
    -webkit-box-shadow: none;
    input {
      font-size: 16px !important;
    }
  }
  .v-expansion-panels {
    max-width: 1000px;
    .v-expansion-panel {
      .v-expansion-panel-header {
        padding: 12px 0px 0px 0px;
      }
      .v-expansion-panel-content__wrap {
        padding: 0px 0px 12px 0px;
      }
      .max-w-little {
        max-width: 135px;
      }
      .max-w-medium {
        max-width: 150px;
      }
    }
  }
}
</style>
<script>
import {
  requiredValue,
  isEmail,
  isNumber,
  // isDate,
  isPasswordMinimumLength
} from "~/validator/validationRules";

import CategoryTitle from "@/components/category/CategoryTitle.vue";

import categoryMixins from "~/mixins/category";

import PoliCustomService from "@/service/poliCustomService";
import UserService from "~/service/userService";

import { mask } from "vue-the-mask";
import forEach from "lodash/forEach";

import { mapGetters } from "vuex";
import { req } from "~/validator/commons";

export default {
  name: "ProfileUpdate",
  components: {
    CategoryTitle
  },
  mixins: [categoryMixins],
  directives: { mask },
  data() {
    return {
      openPanels: [0, 3],
      isProfileFormValid: false,
      isEmailFormValid: false,
      isPasswordFormValid: false,
      showPassword: false,
      oldPassword: null,
      password: null,
      newEmail: null,
      birthdateMenu: false,
      showPreferences: false,
      loadingUserChange: false,
      loadingEmailChange: false,
      loadingPasswordChange: false,
      loadingDisclaimersChange: false,
      requiredRules: requiredValue,
      requiredValue: requiredValue,
      emailRules: [requiredValue("Digitare l'e-mail"), isEmail()],
      dateRules: [requiredValue("Digitare la data di nascita")],
      isEmail: isEmail,
      isNumber: isNumber,
      fidelityCardRules: [
        requiredValue(),
        isNumber("Deve contenere solo cifre")
      ],
      passwordRules: [
        requiredValue("Digitare la password"),
        isPasswordMinimumLength()
      ],
      passwordRulesNew: [
        requiredValue("Digitare la password"),
        this.customPasswordRule()
      ],

      emailConfirmRules: [
        v => !!v || "Confermare l'e-mail",
        v => v === this.newEmail || "Le e-mail non coincidono"
      ],
      passwordConfirmRules: [
        v => !!v || "Confermare la password",
        v => v === this.password || "Le password non coincidono"
      ],
      userData: {
        person: {},
        billingAddress: {}
      },
      registrationModules: [],
      disclaimers: {
        newsletter: "false",
        marketing: "false",
        statistics: "false"
      },

      cities: [],
      response: {}
    };
  },
  computed: {
    ...mapGetters({
      isBetaTester: "cart/isBetaTester"
    }),
    birthDate: {
      get: function() {
        if (this.userData.person.birthDate) {
          return this.$dayjs(this.userData.person.birthDate).format(
            "DD/MM/YYYY"
          );
        } else {
          return null;
        }
      },
      set: function(value) {
        this.userData.person.birthDate = this.$dayjs(
          value,
          "DD/MM/YYYY"
        ).format("YYYY-MM-DD");
      }
    },
    developmentLink() {
      //da testare se device esiste veramente in vue.js
      // eslint-disable-next-line no-undef
      let platform = device ? device.platform.toLowerCase() : "ios";
      return "/mobile/develop/" + platform + "/index.html";
    }
  },
  methods: {
    customPasswordRule(error) {
      // eslint-disable-next-line no-unused-vars
      var errorMessage = error
        ? error
        : "La password deve essere lunga almeno 8 caratteri, contenere maiuscole, minuscole e almeno un numero";
      return function(v) {
        const errorMessage =
          "La password deve contenere almeno 8 caratteri, una maiuscola, una minuscola e un numero";
        const minLength = /.{8,}/.test(v);
        const hasUppercase = /[A-Z]/.test(v);
        const hasLowercase = /[a-z]/.test(v);
        const hasNumber = /[0-9]/.test(v);
        return (
          // eslint-disable-next-line no-undef
          !req(v) ||
          (minLength && hasUppercase && hasLowercase && hasNumber) ||
          errorMessage
        );
      };
    },
    toggleShowPassword(e) {
      e.preventDefault();
      this.showPassword = !this.showPassword;
    },
    async getUserData() {
      const result = await UserService.getUserDetail();

      if (result) {
        this.userData = result;
      }
      console.log("user: ", this.userData);
    },
    async loadCitiesList() {
      if (!this.cities.length) {
        let data = await PoliCustomService.getCityList();
        if (data) {
          this.cities = data;
        }
      }
    },
    async getDisclaimerOptions() {
      try {
        let response = await UserService.getUserDisclaimerListByType(2);
        if (response) {
          this.registrationModules = response.registrationModules;
          if (this.registrationModules && this.registrationModules.length) {
            let items = this.registrationModules[0]
              .registrationModuleDisclaimers;
            forEach(items, item => {
              switch (item.registrationDisclaimer.registrationDisclaimerId) {
                case 1:
                  this.disclaimers.statistics = item.value;
                  break;
                case 2:
                  this.disclaimers.marketing = item.value;
                  break;
                case 3:
                  this.disclaimers.newsletter = item.value;
                  break;
              }
            });
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.showPreferences = true;
      }
    },

    setCityAndProvince(value) {
      this.userData.billingAddress.city = value.name;
      this.userData.billingAddress.province = value.province.name;
    },
    async updateProfile() {
      let _this = this;
      try {
        _this.response = {};
        _this.loadingUserChange = true;
        if (_this.$refs.profileform.validate()) {
          // _this.userData.defaultStoreAddress = {
          //   addressId: _this.userData.defaultStoreAddressId
          // };
          let params = _this.userData;
          params.firstName = _this.userData.person.firstName;
          params.lastName = _this.userData.person.lastName;
          let res = await PoliCustomService.updateUserDetail(params);
          _this.loadingUserChange = false;
          if (res) {
            this.loadCart();
          }
        }
      } catch (response) {
        _this.response = response;
      } finally {
        _this.loadingUserChange = false;
      }
    },
    async updatePassword() {
      let _this = this;
      try {
        _this.response = {};
        _this.loadingPasswordChange = true;
        if (_this.$refs.passwordform.validate()) {
          let res = await PoliCustomService.updatePassword(
            _this.oldPassword,
            _this.password
          );
          _this.loadingPasswordChange = false;
          if (res) {
            _this.loadCart();
          }
        }
      } catch (response) {
        _this.response = response;
      } finally {
        _this.loadingPasswordChange = false;
      }
    },
    async updateEmail() {
      let _this = this;
      try {
        _this.response = {};
        _this.loadingEmailChange = true;
        if (_this.$refs.emailform.validate()) {
          let res = await UserService.updateEmailRequest(this.newEmail);
          _this.loadingEmailChange = false;
          if (res) {
            _this.loadCart();
          }
        }
      } catch (response) {
        _this.response = response;
      } finally {
        _this.loadingEmailChange = false;
      }
    },

    async updateDisclaimers() {
      try {
        this.response = {};
        if (this.registrationModules[0]) {
          forEach(
            this.registrationModules[0].registrationModuleDisclaimers,
            item => {
              switch (item.registrationDisclaimer.registrationDisclaimerId) {
                case 1:
                  item.value = this.disclaimers.statistics;
                  break;
                case 2:
                  item.value = this.disclaimers.marketing;
                  break;
                case 3:
                  item.value = this.disclaimers.newsletter;
                  break;
              }
            }
          );
          this.loadingDisclaimersChange = true;
          console.log(this.registrationModules[0]);
          await UserService.updateUserDisclaimers(this.registrationModules[0]);
          this.loadingDisclaimersChange = false;
        }
      } catch (response) {
        this.response = response;
      } finally {
        this.loadingDisclaimersChange = false;
      }
    },
    onAppTrackingTransparency() {
      window.plugins.impacTracking.trackingAvailable(
        function(available) {
          if (!available) {
            console.log(
              "Tracciamento non disponibile o disabilitato dall'utente"
            );
            window.plugins.impacTracking.canRequestTracking(
              function(result) {
                // Show results in the console
                console.log(result);
                if (result == true) {
                  window.plugins.impacTracking.requestTracking(
                    undefined,
                    function(res) {
                      console.log(res);
                      this.disabledTrackingTransparency = false;
                    },
                    function(err) {
                      console.log(err);
                      //TODO: tracking non abilitato
                      this.disabledTrackingTransparency = true;
                    }
                  );
                }
              },
              function(err) {
                console.log(err);
                //TODO: c'è stato un errore: tracking non abilitato
                this.disabledTrackingTransparency = true;
              }
            );
          } else {
            window.plugins.impacTracking.requestTracking(
              undefined,
              function(res) {
                console.log(res);
                this.disabledTrackingTransparency = false;
              },
              function(err) {
                console.log(err);
                this.disabledTrackingTransparency = true;
              }
            );
          }
        },
        function(err) {
          console.log(err);
          this.disabledTrackingTransparency = true;
        }
      );
    }
  },
  async mounted() {
    await this.loadCitiesList();
    await this.getUserData();
    await this.getDisclaimerOptions();
    if (this.$route.params.navToPreferences) {
      this.$vuetify.goTo(this.$refs.consentform);
    }
  }
};
</script>
